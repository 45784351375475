import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import BackgroundImage from "../components/background-image";
import BGImage from "../images/forest.jpg"
import BGImageM from "../images/forest-m.jpg"
import ReviewSection from "../components/review-section";
import CtaBar from "../components/cta-bar";
import {Helmet} from "react-helmet";

const Testimonials = () => {
    return(
        <main>
            <Helmet>
                <title>Rancho Bernardo Maids - Testimonials</title>
                <meta name="description" content="Rancho Bernardo Maids offers the best cleaning services, deep cleans, & move outs for houses, apartments, condos & more" />
                <meta name="keywords" content="Deep Cleaning, Standard House Cleaning, Move Outs, Party Clean Ups"/>
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Nav/>
            <BackgroundImage image={BGImage} mobileImage={BGImageM} alt={"forest background"} height={"h-128"} hero={true}>
                <h1 className={"text-6xl text-center text-white p-6"}>Testimonials</h1>
            </BackgroundImage>
            <ReviewSection/>
            <CtaBar/>
            <Footer/>
        </main>
    )
}

export default Testimonials